<template>
  <DgOverview :widget="widget" />
</template>
<script>
import MixinFixWidget from "@/mixins/fixWidget";
import { DgOverview } from "@engenharia/dashgoo-widgets";

export default {
  name: "RenderWidgetOverview",
  mixins: [MixinFixWidget],
  components: {
    DgOverview,
  },
};
</script>
